import { BetslipBetType, MatchType } from '../../../utils/types';
import { betUtilsErrors, betUtilsConstants, formatMessage } from '../../../utils/functions-betslip';
import { getBetBuilderOutcomeValue, getOutcomeValue } from '../../../../../../modules/bets/utils/betUtils';
import {
  comb,
  formatTotalOddsValue,
  formatDate,
  canCashout,
  liveScore,
  formatBetTitle,
  formatOddName,
  getTr,
  floorFigure,
  isWin1Odd,
  isWin2Odd,
  isDrawOdd,
} from '../../../utils/functions';
import crests from '../../../../../../modules/bets/utils/crests';
import { debug } from '../../../../../../modules/bets/utils';
import { betBuilderMustUpdateBBF } from '../../../../../../modules/bets/store/actions/betBuilder';
import store from '../../../../../../store';

export const buildBets = (
  bets: BetslipBetType[],
  matches: { [idMatch: string]: MatchType },
  bonusEvaluate: any,
  tournamentEvaluate: any,
  walletBonuses: any,
  t: any,
  language: string,
  isWinnerFun: boolean = false,
): any[] => {
  const walletBonusesHash: { [id: string]: string } = {};
  if (walletBonuses) {
    walletBonuses.forEach((wb: any) => {
      walletBonusesHash[wb.id] = wb.name;
    });
  }

  const rb = bets
    .map((b, i) => {
      let error = '';

      const cm = { ...matches[b.idMatch] };

      if (!cm) return null;

      const bm = b.match;
      let bv;
      let rv = null;

      let rm = cm;
      if (!rm) {
        rm = bm;
        error = betUtilsErrors[betUtilsConstants.MATCH_NULL];
      } else {
        if (b.reoffered) {
          rv = b.reofferedOdd;
        }

        if (b.betType === 'betBuilder') {
          const cv = getBetBuilderOutcomeValue(cm, b, bets);

          //debug('betbuilder cv', cv);

          if (typeof cv === 'number') {
            error = betUtilsErrors[cv];
          } else {
            bv = getBetBuilderOutcomeValue(bm, b, bets);

            //debug('betbuilder bv', bv);

            if (typeof bv === 'number') {
              error = betUtilsErrors[bv];
            } else {
              if (cv !== bv) {
                setTimeout(() => {
                  store.dispatch(betBuilderMustUpdateBBF(bm.mType, b.idMatch));
                }, 0);
              }

              bv = b.prevOdd;
            }
          }
        } else {
          const cv = getOutcomeValue(cm, b, bets);
          if (cv < 0) {
            error = betUtilsErrors[cv];
          } else {
            if (b.oddValueChanged) {
              rv = cv;
            }

            bv = getOutcomeValue(bm, b, bets);
            if (bv < 0) {
              error = betUtilsErrors[bv];
            }
          }
        }
      }

      const evaluateMessages: string[] = [];
      let evaluateValid = true;

      if (!isWinnerFun && bonusEvaluate && bonusEvaluate.success && bonusEvaluate.data) {
        evaluateValid = bonusEvaluate.data.valid;
        bonusEvaluate.data.details &&
          bonusEvaluate.data.details.forEach((ev: any) => {
            if (ev.error && ev.error.type === 'outcome') {
              if (b.idMbo === ev.error.idMbo) {
                const args = [...ev.error.args];
                const noWager = evaluateValid
                  ? walletBonusesHash[ev.id]
                    ? ' - ' + t('Fara Rulaj') + ': '
                    : t('Fara Rulaj') + ': '
                  : ': ';
                args[0] = (walletBonusesHash[ev.id] ? walletBonusesHash[ev.id] + noWager : noWager) + t(args[0]); // see if we have a translation for that message
                evaluateMessages.push(formatMessage(...args));
              }
            }
          });
      }

      cm.canCashout = canCashout(cm);
      cm.matchDateTimeString = formatDate(cm.matchDateTime, language);
      if (cm.mType === 'live') {
        const ls = liveScore(cm, cm.currentStatus);
        if (Array.isArray(ls.intervals) && ls.intervals.length > 0) {
          ls.intervals = ls.intervals.map((interval) => {
            return { percent: interval };
          });
        }
        cm.scoreInfo = ls;
      }

      let matchBets = cm.matchBets;
      let actualMatch = cm;

      if (b.idMb.indexOf(b.idMatch) !== 0) {
        if (cm.periods && Array.isArray(cm.periods)) {
          let found = false;

          for (const p of cm.periods) {
            if (b.idMb.indexOf(p.idMatch) !== 0) {
              continue;
            }

            if (!p.matchBets) {
              continue;
            }

            if (p.matchBets.find((mb) => mb.idMb === b.idMb)) {
              actualMatch = p;
              matchBets = p.matchBets;
              found = true;
              break;
            }

            if (found) {
              break;
            }
          }
        }
      }

      //console.log('MatchDetails[bet]', b);

      const market = matchBets ? matchBets.find((mb) => mb.idMb === b.idMb) : null;

      let marketName = '';
      let oddName = '';

      if (market) {
        marketName = formatBetTitle(market, actualMatch, null, language);

        const outcome = market.mbOutcomes ? market.mbOutcomes.find((mbo) => mbo.idMbo === b.idMbo) : null;

        if (outcome) {
          oddName = formatOddName(market.idBet, outcome, actualMatch, null, language);
        } else {
          // @ts-ignore
          oddName = formatOddName(market.idBet, { idBo: b.idBo }, actualMatch, null, language);
        }
      }

      if (oddName && marketName) {
        if (window.config.replaceInBets1x2 === '1') {
          if (isWin1Odd(marketName, oddName)) {
            oddName = getTr(actualMatch.team1Name, language);
            oddName = oddName?.replace?.('/', ' / ');
          } else if (isWin2Odd(marketName, oddName)) {
            oddName = getTr(actualMatch.team2Name, language);
            oddName = oddName?.replace?.('/', ' / ');
          } else if (isDrawOdd(marketName, oddName)) {
            oddName = 'ODD_DRAW';
            oddName = oddName?.replace?.('/', ' / ');
          }
        }
      }
      if (b.betType === 'betBuilder') {
        cm.outcomes = [
          {
            oddName: '',
            oddValue: floorFigure(bv),
            marketName: 'Combined Odds',
          },
        ];
      } else if (oddName && marketName) {
        cm.outcomes = [
          {
            oddName: oddName,
            oddValue: floorFigure(bv),
            marketName: marketName,
          },
        ];
      } else {
        cm.outcomes = [];
      }

      if (bm && bm) {
        cm.team1LogoUrl = crests(bm.team1Name, bm, 1);
        cm.team2LogoUrl = crests(bm.team2Name, bm, 2);
      }

      const info = {
        bet: {
          betType: b.betType,
          bb: b.bb,
          valid: true,
          idSport: b.idSport,
          idMatch: b.idMatch,
          idBet: b.idBet,
          idMb: b.idMb,
          idBo: b.idBo,
          idMbo: b.idMbo,
          fixed: b.fixed,
          oddValueChanged: b.oddValueChanged,
          odd: floorFigure(b.odd),
          reofferedOdd: b.reofferedOdd,
          reoffered: b.reoffered,
          hasError: !!error,
          betValue: bv,
          reofferedValue: floorFigure(rv),
          evaluateMessages: evaluateMessages.map((m) => ({ message: m })),
          evaluateValid: evaluateValid,
          marketName: b.betType === 'betBuilder' ? 'Combined Odds' : marketName,
          oddName: b.betType === 'betBuilder' ? '' : oddName,
          hasTournamentEvalError:
            tournamentEvaluate &&
            tournamentEvaluate.data &&
            tournamentEvaluate.data.errorBets &&
            tournamentEvaluate.data.errorBets[b.idMbo],
        },
        match: { ...bm, ...cm },
      };

      return info;

      /*
    debug('evaluateMessages', evaluateMessages);


    const card =
      rm.mType === 'live' ? (
        <Match
          idMatch={b.idMatch}
          mType={'live'}
          oddSelected={b.idMbo}
          bgColor={error !== '' ? '#FFEAD8' : ''}
          inBetSlip={true}
          showFixed={showFixed}
          disableDetails={true}
          hasError={!!error}
          bv={bv}
          rv={rv}
          evaluateMessages={evaluateMessages}
          evaluateValid={evaluateValid}
          className={first && evaluateMessages.length !== 0 ? 'scroll-to-this-bet' : ''}
          hasTournamentEvalError={
            tournamentEvaluate &&
            tournamentEvaluate.data &&
            tournamentEvaluate.data.errorBets &&
            tournamentEvaluate.data.errorBets[b.idMbo]
          }
        />
      ) : (
        <PreMatchSimple
          idMatch={b.idMatch}
          mType={'prematch'}
          oddSelected={b.idMbo}
          margin="0"
          bgColor={error !== '' ? '#FFEAD8' : ''}
          showFixed={showFixed}
          bv={bv}
          rv={rv}
          evaluateMessages={evaluateMessages}
          evaluateValid={evaluateValid}
          className={first && evaluateMessages.length !== 0 ? 'scroll-to-this-bet' : ''}
          hasTournamentEvalError={
            tournamentEvaluate &&
            tournamentEvaluate.data &&
            tournamentEvaluate.data.errorBets &&
            tournamentEvaluate.data.errorBets[b.idMbo]
          }
        />
      );

    if (evaluateMessages.length !== 0) first = false;

    if (error !== '') {
      return (
        <div key={i} className={clsx(classes.oddWrapper, classes.unavailable)}>
          <div className={classes.unavailableTitle}>{error}</div>
          <div className={classes.unavailableContent}>
            {card}
            <div className={classes.centered}>
              <Button
                component="div"
                className={clsx(classes.button, classes.remove)}
                onClick={() => onRemoveBet(mType, b.idMbo)}
              >
                {t('Unavailable event - Remove')}
              </Button>
            </div>
          </div>
        </div>
      );
    }

    if (b.oddValueChanged) {
      return (
        <div key={i} className={clsx(classes.oddWrapper, classes.oddsChanged)}>
          {card}
          <div className={classes.centered}>
            <AnimatedButton
              title={t('Odds changed - Click to accept the changes')}
              onClick={() => onAcceptOddChange(mType, b.idMbo)}
            />
          </div>
        </div>
      );
    }

    */
    })
    .filter((b) => b != null);

  return rb;
};

export const buildSystems = (bets: any[], currentSystems: any[] = []) => {
  const numSystems = bets.length;
  const numFixedBets = bets.reduce((acc, b) => acc + (b.bet.fixed ? 1 : 0), 0);
  const numAvailableSystems = numSystems - numFixedBets;

  const rs = [];

  for (let i = 2; i < numAvailableSystems; i++) {
    rs.push(
      {
        system: i,
        systemName:
          (numFixedBets > 0 ? numFixedBets + 'F ' : '') +
          i +
          '/' +
          numAvailableSystems +
          ' (' +
          formatTotalOddsValue(comb(numAvailableSystems, i), true) +
          ')',
        selected: currentSystems.includes(i.toString()),
      },
      /*
      <TicketSystem
        key={i}
        system={i}
        systemName={
          (numFixedBets > 0 ? numFixedBets + 'F ' : '') +
          i +
          '/' +
          numAvailableSystems +
          ' (' +
          formatTotalOddsValue(comb(numAvailableSystems, i), true) +
          ')'
        }
        mType={'none'}
        provider={provider}
      />
      */
    );
  }

  return rs;
};
